import axios from "axios";
import { HOST } from "../strings";

type Props = {
  notransaksi: string;
};

const deleteTransactionBranches = ({ notransaksi }: Props) => {
  const url = `${HOST}/hapustransaksichart/${notransaksi}`;

  return axios.delete(url);
};

export default deleteTransactionBranches;
