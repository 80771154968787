import axios, { AxiosRequestConfig } from "axios";
import { HOST } from "@/strings";

const config: AxiosRequestConfig = {
  timeout: 8888,
};

type GantiCabangProps = {
  cabang: string;
  no_hp: string | number;
  no_transaksi: string;
};

export const gantiCabang = (props: GantiCabangProps) => {
  const url = HOST + "/cabang/updatecabangtransaksi";
  return axios.post(url, props, config);
};
