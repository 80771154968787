import axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import styles from "./Loading.module.scss";
import { NotifSlowConnection } from "@/components/NotifSlowConnection";

const LoadingPage: React.FC = () => {
  const [showNotif, setShowNotif] = useState(false);
  let numberOfAjaxCAllPending = 0;
  const delayShowNotif = 10000;

  const show = setTimeout(() => {
    setShowNotif(true);
  }, delayShowNotif);

  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      numberOfAjaxCAllPending++;
      show;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      numberOfAjaxCAllPending--;
      if (numberOfAjaxCAllPending === 0) {
        clearTimeout(show);
      }
      return response;
    },
    function (error) {
      numberOfAjaxCAllPending--;
      if (numberOfAjaxCAllPending === 0) {
        //hide loader
      }
      return Promise.reject(error);
    },
  );

  useEffect(() => {
    const handleRender = () => {
      const show = setTimeout(() => {
        setShowNotif(true);
      }, delayShowNotif);
      show;
    };
    window.addEventListener("load", handleRender);
    return () => window.removeEventListener("load", handleRender);
  }, []);

  return (
    <div className={styles.loading}>
      <Spinner />
      {showNotif && <NotifSlowConnection onCLose={() => setShowNotif(!showNotif)} />}
    </div>
  );
};

export default LoadingPage;
